import httpClient  from '../api';
const API_CONTROLLER = 'admin/';

export default {
    async filter(search) {
        let url = API_CONTROLLER + 'filter';
        return await httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                userName: search.userName,
                lastName: search.lastName
            }
        });
    },

    delete(id){
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    changePassword(id, newPassword){
        let url = API_CONTROLLER + 'change-password';
        return httpClient.get(url, {
            params: {
                id: id, 
                newPassword: newPassword
            }
        });
    },

    getById(id) {
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    add(data) {
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) {
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },
}